app.filter('tel', function () {
    return function (tel) {

        if (!tel) { return ''; }

        var value = tel.toString().trim().replace(/^\+/, '');

        if (value.match(/[^0-9]/)) {
            return tel;
        }

        var country, city, number;

        if (value.length && value[0] == '1') {
            country = value.slice(0,1);
            value = value.slice(1);
        }

        switch (value.length) {
            case 1:
            case 2:
            case 3:
                city = value;
                break;

            default:
                city = value.slice(0, 3);
                number = value.slice(3);
        }

        var output = '';
        if(number){
            if(value.length>10){
                output = ("" + city + number).trim();
            } else {
                if(number.length>3){
                    number = number.slice(0, 3) + '-' + number.slice(3,7) + (number.length > 7 ? (''+number.slice(7, 15)) : '');
                } else {
                    number = number;
                }
                output = ("(" + city + ") " + number).trim();
            }
        } else{
            output = "(" + city;
        }

        // if (country) output = country + (value.length > 10 ? "" : " ") + output;

        return output;

    };
});
