app.controller('JobsController', ["$scope", "$rootScope", "$window", "$document", "JobsService", "$location", "$filter", "SweetAlert", "countyLimit",
                        function ($scope, $rootScope, $window, $document, JobsService, $location, $filter, SweetAlert, countyLimit) {
    $l = $location;
    $scope.jobs = [];
    $scope.selectedJob = false;
    $scope.partnerInternships = [];

    $scope.requests = {};

    if (!$rootScope.jobForm)
        $rootScope.jobForm = {};

    $scope.loading = true;
    $scope.meta = {
        '_page':1,
        '_perPage':10,
        '_pages':0,
        '_count':0,
    };
    $scope.partnerMeta = {};
    $scope.partnerInternshipsMeta = {};
    $scope.confirmation = {};

    $scope.filters = {};

    if ($rootScope.counties.length==1) {
        $scope.filters.county = $rootScope.counties[0].county;
    }
    
    $scope.pagination = {
        '_page':1,
        '_perPage':10,
        '_pages':0,
        '_count':0,
        getPages: function() {
            var pages = $scope.meta._pages + $scope.partnerMeta._pages;
            if ($scope.meta._pages > 0 && $scope.partnerMeta._pages > 0) pages -= 1;
            if ($scope.isTypeInternship()) {
                pages = $scope.meta._pages + $scope.partnerInternshipsMeta._pages;
                if ($scope.meta._pages > 0 && $scope.partnerInternshipsMeta._pages > 0) pages -= 1;
            }
            return pages;
        },
        getCurrentPartnerPage: function() {
            var page = $scope.pagination._page - $scope.meta._pages;
            if ($scope.pagination.getCurrentListingsPage() > 0) page += 1;
            return page;
        },
        getCurrentListingsPage: function() {
            var page = $scope.pagination._page;
            if (page > $scope.meta._pages) page = $scope.meta._pages;
            return page;
        },
    };

    $scope.isTypeInternship = function() {
        if ($scope.filters.type && $scope.filters.type.toUpperCase() == 'INTERNSHIP') {
            return true;
        }
        return false;
    }

    $scope.dateOptions = {minDate: $rootScope.addDays(new Date(),7), maxDate: $rootScope.addDays(new Date(),30)}

    $scope.parseFilters = function() {
        var search = $location.search();
        ['county', 'title', 'type', 'city', 'category', 'experience_level', 'zip'].forEach(function(key) {
            if (search.hasOwnProperty('nei-filter-'+key)) {
                $scope.filters[key] = search['nei-filter-'+key];
                if (key == 'experience_level') $scope.filters[key] = $filter('ucwords')($scope.filters[key]);
                if (key !== 'type') $scope.user.show.filters = true;
            } else {
                if ($scope.filters[key] && $scope.config.app) delete $scope.filters[key];
            }
        });
        if (search.hasOwnProperty('nei-confirmation-token')) {
            $scope.confirmation.token = search['nei-confirmation-token'];
        }
    }

    $scope.clearFilters = function() {
        $scope.filters = {title : $scope.filters.title, type:$scope.filters.type};

        if ($rootScope.counties.length==1) {
            $scope.filters.county = $rootScope.counties[0].county;
        }

        $scope.filtersChanged();
    }

    $scope.$on('$routeUpdate', function(){
        $scope.parseFilters();
        $scope.filtersChanged();
    });

    $scope.saveFilters = function() {
        if ($scope.config.app) {
            $location.search({});
            Object.keys($scope.filters).forEach(function(key) {
                $location.search('nei-filter-'+key, $scope.filters[key]);
            });
        } else {
            $scope.filtersChanged(false);
        }
    }

    $scope.routeChange = function(event) {
        var route =  $scope.route.route;
        if (route.indexOf('/details/') == 0) {
            $scope.getJobDetails();
            $scope.scrollToTop();
        } else if (route.indexOf('/internship/') == 0) {
            $scope.getInternshipDetails();
        } else if (route == '/') {
            $scope.getJobs();
        } else if (route.indexOf('/confirm') > -1) {
            $scope.confirmJob();
        }
        $scope.parseFilters();
    }
    $scope.$on('routeChange', $scope.routeChange);

    $scope.getJobs = function() {
        if ($scope.requests.jobs) {
            $scope.requests.jobs.abort();
            $scope.requests.jobs = angular.noop();
        }
        if ($scope.route.route == '/') {
            var params = angular.copy($scope.meta);
            var filters = angular.copy($scope.filters);
            params._page = $scope.pagination.getCurrentListingsPage();

            if (filters.hasOwnProperty('title')) {
                if (filters.title && filters.title.length > 0) {
                    var value = "%"+filters.title+"%";
                    filters['_or'] = {
                        title_like: value,
                        company_like: value,
                        qualifications_like: value,
                        responsibilities_like: value,
                        experience_like: value,
                        education_certifications_like: value,
                        languages_like: value,
                        benefits_like: value,
                        job_skills_like: value,
                        notes_like: value,
                        tags: {
                            tag_like: value,
                            tag_type: "TAGS"
                        },
                    };
                }
                delete filters['title'];
            }

            if (filters.hasOwnProperty('category')) {
                if (filters.category && filters.category.length > 0) {
                    filters['categories'] = {
                        category: filters['category'],
                    };
                }
                delete filters['category'];
            }

            params['_filters'] = filters;
            params['_widget'] = true;
            $scope.requests.jobs = JobsService.getJobs(params);
            $scope.requests.jobs.then(function(res){
                $scope.jobs = res.data.data;
                $scope.meta = res.data.meta;
                $scope.requests.jobs = angular.noop();
            }).catch(function(res){
                $scope.requests.jobs = angular.noop();
            });
        }
    }

    $scope.confirmJob = function() {
        if ($scope.route.route == '/post-a-job/confirm') {
            JobsService.confirmJob($scope.confirmation.token, {}).then(function(res){
                $scope.confirmation.success = true;
            }).catch(function(res){
                $scope.confirmation.success = false;
            });
        }
    }

    $scope.getJobDetails = function() {
        if ($scope.route.route.indexOf('/details/') == 0) {
            var id = $scope.route.getParam('id');
            JobsService.getJobWithId(id).then(function(res){
                if (res.data.data.length > 0) {
                    $scope.selectedJob = res.data.data[0];
                } else {
                    $scope.selectedJob = false;
                }
            }).catch(function(res){

            });
        }
    }

    $scope.getInternshipDetails = function() {
        if ($scope.route.route.indexOf('/internship/') == 0) {
            var id = $scope.route.getParam('id');
            JobsService.getInternshipWithId(id).then(function(res){
                if (res.data.data.length > 0) {
                    $scope.selectedJob = res.data.data[0];
                } else {
                    $scope.selectedJob = false;
                }
            }).catch(function(res){

            });
        }
    }

    $scope.getPartnerJobs = function() {
        if ($scope.requests.partner) {
            $scope.requests.partner.abort();
            $scope.requests.partner = angular.noop();
        }
        if ($scope.route.route == '/') {
            $scope.partnerMeta._perPage = 10;
            var params = angular.copy($scope.partnerMeta);
            params._page = $scope.pagination.getCurrentPartnerPage();
            var filters = angular.copy($scope.filters);
            if (filters.hasOwnProperty('title')) {
                if (typeof filters.title == 'undefined' || filters.title.length < 1) {
                    delete filters['title'];
                }
            }
            params['_filters'] = filters;
            $scope.requests.partner = JobsService.getPartnerJobs(params);
            $scope.requests.partner.then(function(res){
                if (res.data.SearchResults) {
                    $scope.partnerJobs = res.data.SearchResults;
                    if (res.data.Meta) $scope.partnerMeta = res.data.Meta;
                    else {
                        $scope.partnerMeta = {
                            '_page':1,
                            '_perPage':$scope.partnerJobs.length,
                            '_pages':1,
                            '_count':$scope.partnerJobs.length,
                        }
                    }
                } else {
                    $scope.partnerJobs = false;
                }
                $scope.requests.partner = angular.noop();
            }).catch(function(res){
                $scope.requests.partner = angular.noop();
            });
        }
    }

    $scope.getPartnerInternships = function() {
        if ($scope.requests.partnerInternships) {
            $scope.requests.partnerInternships.abort();
            $scope.requests.partnerInternships = angular.noop();
        }
        if ($scope.route.route == '/') {
            $scope.partnerInternshipsMeta._perPage = 10;
            var params = angular.copy($scope.partnerInternshipsMeta);
            params._page = $scope.pagination.getCurrentPartnerPage();
            var filters = angular.copy($scope.filters);
            var and = [];
            if (filters.hasOwnProperty('title')) {
                if (filters.title && filters.title.length > 0) {
                    var or = {};
                    var value = "%"+filters.title+"%";
                    or.title_like = value;
                    or.company_like = value;
                    or.description_like = value;
                    or.long_description_like = value;
                    and.push({_or:or});
                }
                delete filters['title'];
            }
            if (filters.hasOwnProperty('category')) {
                if (filters.category && filters.category.length > 0) {
                    var or = {};
                    var value = "%"+filters.category+"%";
                    or.title_like = value;
                    or.company_like = value;
                    or.description_like = value;
                    or.long_description_like = value;
                    and.push({_or:or});
                }
                delete filters['category'];
            }
            if (and.length > 0) filters._and = and;
            params['_filters'] = filters;
            $scope.requests.partnerInternships = JobsService.getPartnerInternships(params);
            $scope.requests.partnerInternships.then(function(res){
                if (res.data.data) {
                    $scope.partnerInternships = res.data.data;
                    if (res.data.meta) $scope.partnerInternshipsMeta = res.data.meta;
                    else {
                        $scope.partnerInternshipsMeta = {
                            '_page':1,
                            '_perPage':$scope.partnerJobs.length,
                            '_pages':1,
                            '_count':$scope.partnerJobs.length,
                        }
                    }
                } else {
                    $scope.partnerInternships = false;
                }
                $scope.requests.partnerInternships = angular.noop();
            }).catch(function(res){
                $scope.requests.partnerInternships = angular.noop();
            });
        }
    }

    $scope.paginationChanged = function()  {
        if ($scope.pagination._page <= $scope.meta._pages) $scope.getJobs();
        if ($scope.pagination._page == 1 || $scope.pagination._page >= $scope.meta._pages) {
            if ($scope.isTypeInternship()) {
                $scope.getPartnerInternships();
            } else {
                $scope.getPartnerJobs();
            }
        }
        $scope.scrollToTop();
    }

    $scope.filtersChanged = function(save) {
        $scope.pagination._page = 1;
        if (save !== false) $scope.saveFilters();
        $scope.getJobs();
        $scope.getPartnerJobs();
        $scope.getPartnerInternships();
    }

    $scope.resetForm = function() {
        $rootScope.jobForm = {
            company: $scope.jobForm.company || '',
            contact_name: $scope.jobForm.contact_name || '',
            contact_phone: $scope.jobForm.contact_phone || '',
            contact_email: $scope.jobForm.contact_email || '',
            address: $scope.jobForm.address || '',
            city: $scope.jobForm.city || '',
            state: $scope.jobForm.state || '',
            zip: $scope.jobForm.zip || '',
            county: $scope.jobForm.county || '',
        };
        $scope.jobForm.targetIndustries = [];
        $scope.jobForm.categories = [];
        $scope.route.change('/post-a-job');
    }

    $scope.toggleTargetIndustry = function(option) {
        if (!$scope.jobForm.targetIndustries) $scope.jobForm.targetIndustries = [];
        var index = $scope.jobForm.targetIndustries.indexOf(option);
        if (index > -1) {
            $scope.jobForm.targetIndustries.splice(index,1);
        } else {
            $scope.jobForm.targetIndustries.push(option);
        }
    }

    $scope.toggleCategory = function(option) {
        if (!$scope.jobForm.categories) $scope.jobForm.categories = [];

        var names = []
        for (var i in $scope.jobForm.categories) {
            var o = $scope.jobForm.categories[i]
            names.push(o.category);
        }

        var index = names.indexOf(option.category);
        if (index > -1) {
            $scope.jobForm.categories.splice(index,1);
        } else {
            $scope.jobForm.categories.push(option);
        }
    }

    $scope.hasCategory = function(option) {
        if (!$scope.jobForm.categories) $scope.jobForm.categories = [];
        var names = []
        for (var i in $scope.jobForm.categories) {
            var o = $scope.jobForm.categories[i]
            names.push(o.category);
        }
        return names.indexOf(option.category) > -1;
    }

    $scope.submit = function (form) {

        SweetAlert.swal({
            title: "",
            text: "Please be sure you have thoroughly reviewed all job posting information. By clicking \"SUBMIT\" you agree that your information is correct and will be submitted to the Northeast Indiana Regional Partnership for review and posting. Any changes to this posting after submission must be made directly with the Northeast Indiana Regional Partnership.",
            showCancelButton: true,
            confirmButtonColor: "#0B4593",
            confirmButtonText: "AGREE AND SUBMIT",
            cancelButtonText: "BACK TO FORM",
            closeOnConfirm: true},
        function(confirm){
            if (confirm) {
                JobsService.createJob($scope.jobForm).then(function (res) {
                    if (res.data.id) {
                        $scope.scrollToTop();
                        $scope.route.change('/post-a-job/success');
                    }
                }).catch(function (res) {

                });
            }
        });
    }

    $rootScope.openInternshipLink = function(url){
        SweetAlert.swal({
            title: "",
            text: "Thank you for your interest in applying for an internship position with us. To continue, you'll be taken to our partners at IndianaINTERN.net, a free and easy way to apply for internships—and potentially earn up to 50% of your internship reimbursement funds from the state!\n\nProceed to IndianaINTERN.net?",
            showCancelButton: true,
            confirmButtonColor: "#0B4593",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            closeOnConfirm: true},
        function(confirm){
            if (confirm) {
                // $window.open(url,'_blank');
                var newWin = $window.open('', '_blank');
                newWin.location = url;
            }
        });
    }

    $scope.getURI = function() {
        return $location.absUrl();
    }

    $scope.parseFilters();
    $scope.getJobs();
    $scope.getJobDetails();
    $scope.getPartnerJobs();
    $scope.getPartnerInternships();
}]);

app.factory('JobsService', function ($http, $q, baseUrl) {
    return {
        getJobs: function (params) {
            var deferredAbort = $q.defer();
            var url = baseUrl + 'api/v1/listings';
            if (params && Object.keys(params).length > 0) {
                url += '?';
                if (params.hasOwnProperty('_filters')) {
                    if (Object.keys(params['_filters']).length > 0) {
                        url += '_filters=' + encodeURIComponent(JSON.stringify(params['_filters']))+'&';
                    }
                    delete params['_filters'];
                }
                for (key in params) {
                    url += encodeURIComponent(key)+"="+encodeURIComponent(params[key])+"&";
                }
            }

            var request = $http({ method: "get", url: url, timeout: deferredAbort.promise });

            var promise = request.then(function(response) { return response ; },
                function( response ) { return( $q.reject( "Something went wrong" ) ); }
            );

            promise.abort = deferredAbort.resolve;
            promise.finally(function() {
                    promise.abort = angular.noop();
                    deferredAbort = request = promise = null;
            });
            return promise;
        },
        getPartnerJobs: function (params) {
            var url = baseUrl + 'api/v1/listings/partner';
            var deferredAbort = $q.defer();
            if (params && Object.keys(params).length > 0) {
                url += '?';
                if (params.hasOwnProperty('_filters')) {
                    if (Object.keys(params['_filters']).length > 0) {
                        url += '_filters=' + encodeURIComponent(JSON.stringify(params['_filters']))+'&';
                    }
                    delete params['_filters'];
                }
                for (key in params) {
                    url += encodeURIComponent(key)+"="+encodeURIComponent(params[key])+"&";
                }
            }

            var request = $http({ method: "get", url: url, timeout: deferredAbort.promise });

            var promise = request.then(function(response) { return response ; },
                function( response ) { return( $q.reject( "Something went wrong" ) ); }
            );

            promise.abort = deferredAbort.resolve;
            promise.finally(function() {
                    promise.abort = angular.noop();
                    deferredAbort = request = promise = null;
            });
            return promise;
        },
        getPartnerInternships: function (params) {
            var url = baseUrl + 'api/v1/internships';
            var deferredAbort = $q.defer();
            if (params && Object.keys(params).length > 0) {
                url += '?';
                if (params.hasOwnProperty('_filters')) {
                    if (Object.keys(params['_filters']).length > 0) {
                        url += '_filters=' + encodeURIComponent(JSON.stringify(params['_filters']))+'&';
                    }
                    delete params['_filters'];
                }
                for (key in params) {
                    url += encodeURIComponent(key)+"="+encodeURIComponent(params[key])+"&";
                }
            }

            var request = $http({ method: "get", url: url, timeout: deferredAbort.promise });

            var promise = request.then(function(response) { return response ; },
                function( response ) { return( $q.reject( "Something went wrong" ) ); }
            );

            promise.abort = deferredAbort.resolve;
            promise.finally(function() {
                    promise.abort = angular.noop();
                    deferredAbort = request = promise = null;
            });
            return promise;
        },
        getJobWithId: function (id) {
            return $http.get(baseUrl + 'api/v1/listings/' + id)
        },
        getInternshipWithId: function (id) {
            return $http.get(baseUrl + 'api/v1/internships/' + id)
        },
        createJob: function (params) {
            return $http.post(baseUrl + 'api/v1/listings', params)
        },
        confirmJob: function (token, params) {
            return $http.get(baseUrl + 'api/v1/listings/confirm/'+token, params)
        }
    };
});
