"use strict";

app.directive('popover', function ($templateRequest, $compile) {
    var count = 0;
    return {
        scope: true,
        restrict: 'A',
        link: function link(scope, elem, attrs) {
            var id = count++;
            var url = attrs.popoverTemplate;
            var parent = elem.parent();
            var child = null;

            $templateRequest(url).then(function (html) {
                var template = $compile(html)(scope);
                template.data('popover-count', id);
                child = template;
                parent.append(template);
                template.hide();
                child.bind('click', function (e) {
                    e.stopPropagation();
                });
            });

            elem.bind('click', function (e) {
                e.stopPropagation();
                scope.closeAllPopovers();
                if (child) {
                    child.fadeToggle(200);
                    scope.bindBlur(child);
                }
            });

            scope.bindBlur = function (popover) {
                var parent = popover.closest('.modal_container');
                if (!parent) {
                    parent = neiJquery('body');
                }
                parent.bind('click', function (e) {
                    parent.unbind('click');
                    e.stopPropagation();
                    popover.fadeOut(200);
                });
            };

            scope.closePopover = function () {
                if (child) child.fadeOut(200);
            };

            scope.closeAllPopovers = function () {
                neiJquery(".popover").each(function () {
                    var popover = neiJquery(this);
                    if (child.data('popover-count') != popover.data('popover-count')) {
                        popover.fadeOut(200);
                    }
                });
            };
        },
    }
});
