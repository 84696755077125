app.directive('accessibleForm', function () {
    return {
        restrict: 'A',
        link: function (scope, elem) {

            // set up event handler on the form element
            elem.on('submit', function () {

                // find the first invalid element
                var firstInvalid = elem[0].querySelector('.ng-invalid');

                // if we find one, set focus
                if (firstInvalid) {
                    var elemToFocus = firstInvalid;
                    var input = firstInvalid.querySelector('input');
                    if (input) elemToFocus = input;
                    neiJquery('html, body').animate({
                        scrollTop: neiJquery(elemToFocus).offset().top - 50
                    }, 0);
                    elemToFocus.focus();
                }
            });
        }
    };
});
