"use strict";

app.directive('navSlidedown', function () {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs, ctrl) {
            if (scope.$eval(attrs.navSlidedownAllowed) === false) {
                elem.css('display', '');
            } else {
                if ('down' == attrs.navSlidedownInit) {
                    elem.css('display', '');
                } else {
                    elem.css('display', 'none');
                }
            }
            scope.$watch(attrs.navSlidedown, function (val) {

                var allowed = scope.$eval(attrs.navSlidedownAllowed);

                if (allowed === false) {
                    elem.css('display', '');
                } else {
                    var duration = angular.isUndefined(attrs.slidedownDuration) ? 150 : attrs.slidedownDuration;
                    if (val) {
                        elem.slideDown(duration);
                    } else {
                        elem.slideUp(duration);
                    }
                }
            });
            scope.$watch(attrs.navSlidedownAllowed, function (val) {
                if (val === false) {
                    elem.slideDown(0);
                    elem.css('display', '');
                }
            });
        }
    }
});
