app.factory('BaseService', function ($http, baseUrl) {
    return {
        getCounties: function () {
            return $http.get(baseUrl + 'api/v1/counties')
        },
        getCategories: function () {
            return $http.get(baseUrl + 'api/v1/categories')
        },
        getTargetIndustries: function () {
            return $http.get(baseUrl + 'api/v1/target-industries')
        }
    };
});
