app.controller('NotificationsController', ["$scope", "$rootScope", "$window", "$document", "NotificationsService", function ($scope, $rootScope, $window, $document, NotificationsService) {

    if (!$rootScope.notifications) $rootScope.notifications = {};

    $scope.email = '';

    $scope.submit = function() {
	    // sign up for mailchimp
	    NotificationsService.mailchimp($scope.notifications);

        NotificationsService.post($scope.notifications).then(function(res){
            if (res.data.id) {
                $scope.route.change('/notifications/success');
            } else {
                $scope.error = res.data.message;
            }
        }).catch(function(res){

        });
    }

    if ($scope.route.route == '/notifications/unsubscribe') {
        NotificationsService.unsubscribe($scope.route.getParam('nei-unsubscribe-token')).then(function(res){
            if (res.data.id) {
                $scope.email = res.data.email;
                $scope.unsubscribed = true;
            } else {
                $scope.error = res.data.message;
            }
        }).catch(function(res){

        });

    }
}]);

app.factory('NotificationsService', function ($http, baseUrl) {
    var mailchimpUrl = '//neindiana.us13.list-manage.com/subscribe/post-json?u=8eeb8bb89826182486c80605a&amp;id=202cdf4fe7&c=?';
    return {
        post: function (params) {
            return $http.post(baseUrl + 'api/v1/notifications', params)
        },
        unsubscribe: function (token) {
            return $http.post(baseUrl + 'api/v1/notifications/unsubscribe/'+token)
        },
        mailchimp: function(parms) {

	        $.ajax({
			    url: mailchimpUrl,
			    data: {
			        'EMAIL':parms.email,
			        'b_8eeb8bb89826182486c80605a_202cdf4fe7':'',
		        },
			    dataType: 'jsonp',
		    });

        }
    };
});
