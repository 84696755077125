"use strict";

app.directive('tooltip', function ($templateRequest, $compile) {
    return {
        // scope: true,
        restrict: 'AEC',
        link: function link(scope, elem, attrs) {

            var target = elem.children(".tooltip");

            elem.data('tooltip-state', 0);

            var show = function (event) {
                if (target) {
                    target.fadeIn(200, function () {
                        elem.focus();
                    });
                    elem.data('tooltip-state', 1);
                    elem.focus();
                }
            }

            var hide = function (event) {
                if (target) {
                    target.fadeOut(200);
                    elem.data('tooltip-state', 0);
                }
            }

            var trigger = attrs.tooltipTrigger || 'hover';

            if (trigger == "hover") {
                elem.mouseenter(show);
                elem.mouseleave(hide);
            } else if (trigger == 'click') {
                elem.click(function () {
                    var state = elem.data('tooltip-state') || 0;
                    if (state == 0) show();
                    else hide();
                })

                elem.focusout(function (event) {
                    var state = elem.data('tooltip-state') || 0;
                    if (state == 1) hide();
                });

            } else {
                console.error("Unknown tooltip trigger");
            }
        },
    }
});
