// http://stackoverflow.com/questions/21859151/angularjs-force-scope-property-to-exist-on-angularjs-binding
app.filter("throwUndefined", function ($exceptionHandler) {
    return function (input) {
        if (input === undefined) {
            $exceptionHandler(new Error("Property was undefined"),
                "throwUndefined filter");
        }
        return input;
    };
});
