/**
 * Validator for checking an array length.
 * @example
 *   <input ng-model="user.favoriteColors" validate-length />
 */
app.directive('validateLength', function() {
  return {
    require: 'ngModel',
    link: function(scope, element, attrs, ngModel) {

      var length = attrs.validateLength ? parseInt(attrs.validateLength) : 0;

      // do not set invalid model to undefined, should stay []
      ngModel.$options = {
        allowInvalid: true
      };

      scope.$watch(function () { return ngModel.$modelValue && ngModel.$modelValue.length; }, function() {
        ngModel.$validate(); // validate again when array changes
      });

      ngModel.$validators.length = function() {
        var arr = ngModel.$modelValue;
        if(!arr) { return false; }

        return arr.length >= length;
      };

    }
  };
});
