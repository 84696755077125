app.filter('objectSearch', function () {
    return function (items, search) {

        if (!search) {
            return items;
        }

        var words = search.split(" ");

        var results = [];

        for (var ii in items) {
            var item = items[ii];
            var added = false;
            Object.values(item).forEach(function (val) {
                for (var i in words) {
                    var word = words[i].toLowerCase();
                    if (!added && typeof val == 'string' && val.toLowerCase().startsWith(word)) {
                        results.push(item);
                        added = true;
                    }
                }
            });
        }

        return results;
    };
});
