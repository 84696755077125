app.directive('dynamicController', function($compile, $controller) {
  return {
    restrict: 'A',
    terminal: true,
    link: function(scope, elm, attrs) {
      var lastScope;
      scope.$watch(attrs.dynamicController, function(ctrlName) {
        if (lastScope) lastScope.$destroy();
        var newScope = scope.$new();
        var ctrl = $controller(ctrlName, {$scope: newScope});
        elm.contents().data('$ngControllerController', ctrl);
        $compile(elm.contents())(newScope);

        lastScope = newScope;
      });
    }
  }
})
