app.directive('pagination', function () {
  return {
    restrict: 'E',
    templateUrl: '_directives/pagination.html',
    scope: {
      callback: '=onPageChange',
      currentPage: '=currentPage',
      perPage: '=perPage',
      pages: '=pages',
    },
    link: function ($scope, elem, attrs, ctrl) {
      $scope.pageDidChange = function (newPage) {
        var oldPage = $scope.currentPage;
        if ($scope.callback) $scope.callback(newPage, oldPage);
      }
    },
  };
});
