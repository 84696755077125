// http://stackoverflow.com/a/33176717/4127142
app.directive("limitTo", [function() {
    return {
        restrict: "A",
        require: 'ngModel',
        link: function (scope, element, attrs, ngModel) {
        attrs.$set("ngTrim", "false");
        var limitLength = parseInt(attrs.limitTo, 10);// console.log(attrs);
        scope.$watch(attrs.ngModel, function(newValue) {
            if(ngModel.$viewValue && ngModel.$viewValue.length>limitLength){
                ngModel.$setViewValue( ngModel.$viewValue.substring(0, limitLength ) );
                ngModel.$render();
            }
        });
        }
    }
}]);
