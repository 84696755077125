app.filter('payPeriod', function () {
    return function (input) {
        if (!input) return '';
        var original = input;
        input = input.toLowerCase();
        if (input == 'hour') return 'Hourly';
        if (input == 'year') return 'Salary';
        return original;
    }
});
